<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>我的教练</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="姓名">
                                <el-input v-model="search.user_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="昵称">
                                <el-input v-model="search.user_nick"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号">
                                <el-input v-model="search.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="身份证号">
                                <el-input v-model="search.identity_card"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市">
                                <el-input v-model="search.city"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="user_nick"
                        label="昵称">
                </el-table-column>
                <el-table-column
                        prop="user_name"
                        label="姓名">
                </el-table-column>
                <el-table-column
                        prop="portrait"
                        label="头像照片"
                        width="80">
                    <template slot-scope="scope">
                        <el-avatar shape="square" :size="22" :src="scope.row.portrait"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="avatar_url"
                        label="微信头像"
                        width="80">
                    <template slot-scope="scope">
                        <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="手机号"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="is_show"
                        label="小程序展示"
                        width="160">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_show===0" type="info">不显示</el-tag>
                        <el-tag size="mini" v-if="scope.row.is_show===1">显示</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="publish_state"
                        label="标签"
                        min-width="200">
                    <template slot-scope="scope">
                        <el-tag
                                :key="item.index"
                                v-for="item in scope.row.coach_tag"
                                class="form-tag_colour-tag"
                                effect="dark"
                                size="mini"
                                :color="item.tag_colour">
                            {{ item.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orderby"
                        label="排序（升序）"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="orderby"
                        label="团课授课等级"
                        width="150">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.gradegroup===0" type="info">未设置</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradegroup===1">一星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradegroup===2">二星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradegroup===3">三星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradegroup===4">四星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradegroup===5">五星</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orderby"
                        label="训练营授课等级"
                        width="150">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.gradecamp===0" type="info">未设置</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradecamp===1">一星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradecamp===2">二星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradecamp===3">三星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradecamp===4">四星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradecamp===5">五星</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orderby"
                        label="私教授课等级"
                        width="150">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.gradept===0" type="info">未设置</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradept===1">一星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradept===2">二星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradept===3">三星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradept===4">四星</el-tag>
                        <el-tag size="mini" v-if="scope.row.gradept===5">五星</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="gender"
                        label="性别"
                        width="160">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.gender===0" type="info">未知</el-tag>
                        <el-tag size="mini" v-if="scope.row.gender===1">男</el-tag>
                        <el-tag size="mini" v-if="scope.row.gender===2" type="danger">女</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="identity_card"
                        label="身份证号"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="years"
                        label="教学年限"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="city"
                        label="城市"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="注册时间"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="更新时间"
                        width="150">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="180">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('coach.companycoach.setcoach')"
                                @click="set_coach(scope.row)"
                                type="primary" size="mini">设置教练
                        </el-button>
                        <el-button
                                v-if="is_auth('coach.companycoach.getinfo')"
                                @click="tosee(scope.row.coach_uuid)"
                                size="mini">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

            <!--设置教练-->
            <el-dialog v-if="is_auth('coach.companycoach.setcoach')"
                       title="设置教练"
                       :visible.sync="setcoachPage"
                       width="50%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="教练昵称">
                                <span class="form-span-text">{{ infodata.user_nick }}</span>
                            </el-form-item>
                            <el-form-item label="小程序展示">
                                <el-radio v-model="infodata.is_show" :label="1">显示</el-radio>
                                <el-radio v-model="infodata.is_show" :label="0">隐藏</el-radio>
                            </el-form-item>
                            <el-form-item label="教练标签">
                                <div>
                                    <el-select
                                            @change="add_tag"
                                            v-model="select_tag"
                                            filterable
                                            placeholder="请选择标签"
                                            style="width: 100%">
                                        <el-option
                                                v-for="item in tag_list"
                                                :key="item.tag_uuid"
                                                :label="item.tag_name"
                                                :value="item.tag_name+'|'+item.tag_colour+'|'+item.tag_type">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="form-tag_colour-div">
                                    <el-tag
                                            :key="item.index"
                                            v-for="item in this.infodata.coach_tag"
                                            class="form-tag_colour-tag"
                                            effect="dark"
                                            closable
                                            @close="tagClose(item)"
                                            :color="item.tag_colour">
                                        {{ item.tag_name }}
                                    </el-tag>
                                </div>
                            </el-form-item>
                            <el-form-item label="排序">
                                <el-input-number v-model="infodata.orderby" :step="1" :min="0"
                                                 :max="9999"
                                                 step-strictly
                                ></el-input-number>
                            </el-form-item>
                            <el-form-item label="团课授课等级">
                                <el-radio v-model="infodata.gradegroup" :label="0">不设置</el-radio>
                                <el-radio v-model="infodata.gradegroup" :label="1">一星</el-radio>
                                <el-radio v-model="infodata.gradegroup" :label="2">二星</el-radio>
                                <el-radio v-model="infodata.gradegroup" :label="3">三星</el-radio>
                                <el-radio v-model="infodata.gradegroup" :label="4">四星</el-radio>
                                <el-radio v-model="infodata.gradegroup" :label="5">五星</el-radio>
                            </el-form-item>
                            <el-form-item label="训练营授课等级">
                                <el-radio v-model="infodata.gradecamp" :label="0">不设置</el-radio>
                                <el-radio v-model="infodata.gradecamp" :label="1">一星</el-radio>
                                <el-radio v-model="infodata.gradecamp" :label="2">二星</el-radio>
                                <el-radio v-model="infodata.gradecamp" :label="3">三星</el-radio>
                                <el-radio v-model="infodata.gradecamp" :label="4">四星</el-radio>
                                <el-radio v-model="infodata.gradecamp" :label="5">五星</el-radio>
                            </el-form-item>
                            <el-form-item label="私教授课等级">
                                <el-radio v-model="infodata.gradept" :label="0">不设置</el-radio>
                                <el-radio v-model="infodata.gradept" :label="1">一星</el-radio>
                                <el-radio v-model="infodata.gradept" :label="2">二星</el-radio>
                                <el-radio v-model="infodata.gradept" :label="3">三星</el-radio>
                                <el-radio v-model="infodata.gradept" :label="4">四星</el-radio>
                                <el-radio v-model="infodata.gradept" :label="5">五星</el-radio>
                            </el-form-item>


                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button v-if="is_auth('coach.companycoach.setcoach')" size="medium" type="primary"
                               @click="issavetag">确
                        定
                    </el-button>
                    <el-button size="medium" @click="setcoachPage = false">取 消</el-button>
                </el-row>
            </el-dialog>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '我的教练',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数

            setcoachPage: false,
            // 标签数据
            infodata: {
                user_name: '',
                user_nick: '',
                is_show: 1,
                coach_tag: [],
                orderby: '',
            },
            tag_list: [],       // 标签列表
            select_tag: '',     // 选中的标签
        }
    },
    // 创建
    created() {
        this.init()
        this.gettag()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                user_name: '',
                user_nick: '',
                phone: '',
                identity_card: '',
                city: '',
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coach.companycoach.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(coach_uuid = '') {
            this.$router.push({path: '/coach/companycoach/details', query: {coach_uuid: coach_uuid}})
        },

        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'coach.companycoach.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 设置教练
        set_coach(data) {
            console.log('data', data)
            this.infodata = JSON.parse(JSON.stringify(data))
            this.setcoachPage = true
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
                tag_type: select_tag[2],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.infodata.coach_tag.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.infodata.coach_tag.length > 0) {
                for (let v of this.infodata.coach_tag) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            // console.log('tag', tag);
            // console.log('tag2', this.infodata.coach_tag);
            this.infodata.coach_tag.splice(this.infodata.coach_tag.indexOf(tag), 1);
        },
        // 保存设置
        issavetag() {
            let postdata = {
                api_name: "coach.companycoach.setcoach",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.infodata);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                this.getlist()    // 刷新列表(重新搜索)
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.setcoachPage = false     // 关闭窗口
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        }


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    /*min-height: 200px;*/
}

</style>
